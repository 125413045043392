<template>
  <div class="overview">
    <Loader v-if="isLoading" />
    <div v-else-if="data">
      <vs-row>
        <vs-col vs-w="3" class="mr-2 ml-2">
          <statistics-card-line
            icon="UsersIcon"
            :statistic="data.dashboard.users.amount"
            :statisticTitle="$l.get('overview.users-statistics-header')"
            :chartData="data.dashboard.users.series"
            type="area"
          ></statistics-card-line>
        </vs-col>
        <vs-col vs-w="3" class="mr-2 ml-2">
          <statistics-card-line
            icon="ShoppingCartIcon"
            :statistic="data.dashboard.transactions.amount"
            :statisticTitle="$l.get('overview.transactions-statistics-header')"
            :chartData="data.dashboard.transactions.series"
            color="success"
            type="area"
          ></statistics-card-line>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import InitOverviewInteractor from "@/business/overview/init-overview";
import OverviewScreenController from "@/adapters/controllers/screen-overview";

import StatisticsCardLine from "@/application/components/vuexy/statistics-cards/StatisticsCardLine.vue";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "overview",
  components: { Loader, StatisticsCardLine },
  data() {
    return {
      controller: null,
      interactors: {
        initOverview: null
      },
      data: null,
      isLoading: false,
      searchTimer: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(OverviewScreenController);

    //{ INTERACTORS
    this.interactors.initOverview = this.$injector.get(InitOverviewInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initOverview.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
      data.dashboard.users.series[0].name = this.$l.get(
        "overview.users-statistics-label"
      );
      data.dashboard.transactions.series[0].name = this.$l.get(
        "overview.transactions-statistics-label"
      );
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss"></style>
